import { FC, createElement as h } from "react";
import { makeStyles } from "@material-ui/core";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Author from "../components/author";
import Popular from "../components/popular";
import TagChips from "../components/tag-chips";
import { scale } from "../utils/typography";
import { authors } from "../utils/filters";
import { GatsbyImageProps } from "gatsby-image";
import HubspotForm from "../components/hubspotForm";

const useStyles = makeStyles(theme => ({
  line: {
    marginBottom: theme.spacing(1),
  },
  hgroup: {
    "& > *": {
      marginBottom: "0.2rem",
    },
    marginBottom: "1.5rem",
  },
  date: {
    fontWeight: 300,
  },
  navList: {
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    listStyle: `none`,
    padding: 0,
  },
}));

interface Props {
  data: any;
  location: Location;
  pageContext: any;
}

type Email = string;

const BlogPostTemplate: FC<Props> = ({ data, pageContext, location }) => {
  // TODO: Make this type autogenerated, or accessible elsewhere.
  const post: {
    id: string;
    excerpt: string;
    html: string;
    frontmatter: {
      title: string;
      date: string;
      description: string;
      author?: {
        id: Email;
        first?: string;
        last?: string;
        bio?: string;
        image?: {
          childImageSharp: GatsbyImageProps;
        };
      };
      tags: ReadonlyArray<string>;
    };
  } = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;
  const { author } = post.frontmatter;
  const classes = useStyles();
  const tags = post.frontmatter.tags.filter(tag => !authors.has(tag));

  return h(
    Layout,
    {
      location,
      title: siteTitle,
    },
    h(SEO, {
      title: post.frontmatter.title,
      description: post.frontmatter.description || post.excerpt,
      author: "@clausehound",
    }),
    h(
      Helmet,
      null,
      h("script", { src: "https://www.clausehound.com/dist/clausehound.js" }),
      h("script", {
        id: "hs-script-loader",
        src: `//js.hs-scripts.com/${process.env.HUBSPOT_ACCOUNT_NUMBER}.js`,
      }),
    ),
    h(
      "article",
      null,
      h(
        "header",
        null,
        author?.first && h(Author, author),
        h(
          "hgroup",
          { className: classes.hgroup },
          h(
            "h1",
            {
              className: "post-title",
              style: { marginTop: "1rem" },
            },
            post.frontmatter.title,
          ),
          h(
            "h5",
            {
              className: `${classes.date} post-published-date`,
              style: {
                ...scale(-1 / 5),
              },
            },
            post.frontmatter.date,
          ),
        ),
      ),
      h("main", {
        className: `post-body`,
        dangerouslySetInnerHTML: {
          __html: post.html,
        },
      }),
      h(TagChips, { tags }),
      h("hr", { className: classes.line }),
      h(
        "footer",
        null,
        author?.first &&
          h(Bio, {
            name: author.first,
            email: author.id,
            bio: author.bio,
          }),
      ),
    ),
    h(
      "nav",
      null,
      h(
        "ul",
        { className: classes.navList },
        h(
          "li",
          null,
          previous &&
            h(
              Link,
              {
                to: previous.fields.slug,
                rel: "prev",
              },
              "\u2190 ",
              previous.frontmatter.title,
            ),
        ),
        h(
          "li",
          null,
          next &&
            h(
              Link,
              {
                to: next.fields.slug,
                rel: "next",
              },
              next.frontmatter.title,
              " \u2192",
            ),
        ),
      ),
    ),
    h(
      "footer",
      null,
      h(
        "small",
        null,
        "This article is provided for informational purposes only and does not create a lawyer-client relationship with the reader. It is not legal advice and should not be regarded as such. Any reliance on the information is solely at the reader’s own risk. ",
        h(
          "a",
          { href: "https://clausehound.com/documents" },
          "Clausehound.com",
        ),
        " is a legal tool geared towards entrepreneurs, early-stage businesses and small businesses alike to help draft legal documents to make businesses more productive.",
      ),
      h("section", null, h(HubspotForm, { title: post.frontmatter.title })),
      h(Popular),
    ),
  );
};

export default BlogPostTemplate;
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author {
          id
          first
          last
          bio
          image {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tags
      }
    }
  }
`;
